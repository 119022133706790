import { DEFAULT_CHAIN_ID, ZKSYNC_SEPOLIA } from 'config/chains'
import { CLOUD_FRONT_URL } from 'config/constants'
import { ethers } from 'ethers'
import { ZKSYNC } from './chains'
import { IS_VERBOSE } from './development'

export type Token = {
  name: string
  symbol: string
  baseSymbol?: string
  decimals: number
  address: string
  priceDecimals?: number
  wrappedAddress?: string
  coingeckoUrl?: string
  explorerUrl?: string
  reservesUrl?: string
  imageUrl?: string
  pythId?: string
  baseId?: string
  id?: string
  baseAddress?: string
  quoteAddress?: string

  isUsdg?: boolean
  isNative?: boolean
  isWrapped?: boolean
  isShortable?: boolean
  isStable?: boolean
  isSynthetic?: boolean
  isTempHidden?: boolean
  isChartDisabled?: boolean
  isV1Available?: boolean
  isPlatformToken?: boolean

  isChartForMultiChartPage?: boolean
  needsLiveDataFetching?: boolean
  tag?: string
}

export const NATIVE_TOKEN_ADDRESS = ethers.constants.AddressZero

export const TOKENS: { [chainId: number]: Token[] } = {
  [ZKSYNC_SEPOLIA]: [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      id: 'ethereum',
      address: ethers.constants.AddressZero,
      pythId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      isNative: true,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
      isV1Available: true,
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
      tag: 'L1',
    },
    {
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      id: 'ethereum',
      decimals: 18,
      address: '0x138DCa29f0dF3492bce88f1E897a23b04c161913',
      pythId:
        '0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6',
      isWrapped: true,
      baseSymbol: 'ETH',
      imageUrl: CLOUD_FRONT_URL + '/assets/weth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
    },
    {
      name: 'ETH/SOL',
      symbol: 'ETH/SOL',
      address: '0xB615d0ae9011f339614947deE09B2D7d2e134567',
      pythId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      baseId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      quoteAddress: '0x138DCa29f0dF3492bce88f1E897a23b04c161913',

      baseAddress: '0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      //TODO: remove explorerUrl in future
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'SOL/ETH',
      symbol: 'SOL/ETH',
      address: '0xea3E1b07Afc7021F1F3eC5F2e2B02C78E68418bc',
      pythId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      baseId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      quoteAddress: '0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745',
      baseAddress: '0x138DCa29f0dF3492bce88f1E897a23b04c161913',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      //TODO: remove explorerUrl in future
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'Wrapped SOL (Wormhole)',
      symbol: 'SOL',
      id: 'solana',
      pythId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      decimals: 9,
      isSynthetic: true,
      address: '0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745',
      imageUrl: CLOUD_FRONT_URL + '/assets/sol.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/solana',
      explorerUrl:
        'https://arbiscan.io/token/0x2bCc6D6CdBbDC0a4071e48bb3B969b06B3330c07',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
      tag: 'L1',
    },
    // {
    //   name: 'Bridged USDC (USDC.e)',
    //   symbol: 'USDC.e',
    //   decimals: 6,
    //   address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    //   isStable: true,
    //   imageUrl: CLOUD_FRONT_URL + '/assets/usdc.svg',
    //   coingeckoUrl: 'https://www.coingecko.com/en/coins/usd-coin',
    //   explorerUrl:
    //     'https://arbiscan.io/token/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    //   isV1Available: true,
    // },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      address: '0x9B2bcE861E8455AD88eaBAD7C73ce96edD663C7c',
      pythId:
        '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
      isStable: true,
      isV1Available: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/usdc.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/usd-coin',
      explorerUrl:
        'https://arbiscan.io/address/0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      needsLiveDataFetching: true,
    },
    {
      name: 'GMX Market tokens',
      symbol: 'RM',
      address: '<market-token-address>',
      decimals: 18,
      imageUrl:
        'https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png',
      isPlatformToken: true,
    },
    {
      name: 'Bitcoin',
      symbol: 'BTC',
      id: 'bitcoin',
      address: '0x393049F868575b9AE9Fc5bbFa028D5Fa30Dd9760',
      pythId:
        '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
      decimals: 8,
      imageUrl: CLOUD_FRONT_URL + '/assets/btc.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      tag: 'L1',
    },
    {
      name: 'PEPE',
      symbol: 'PEPE',
      id: 'pepe',
      address: '0x1d39C107308B3C963Cf0305B17710450AFFe0C5E',
      pythId:
        '0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4',
      priceDecimals: 16,
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/pepe.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      needsLiveDataFetching: true,
      tag: 'MEME',
    },
    {
      name: 'ETH/BTC',
      symbol: 'ETH/BTC',
      address: '0x6EC99bf62159BE5199C24179E404B4B4406bFC6B',
      pythId:
        '0xc96458d393fe9deb7a7d63a0ac41e2898a67a7750dbd166673279e06c868df0a',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'INJ',
      symbol: 'INJ',
      decimals: 18,
      priceDecimals: 4,
      address: '0x00FF6962e7A5799e1497CEcc915453929C4E3EE5',
      pythId:
        '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/inj.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'TON',
      symbol: 'TON',
      decimals: 9,
      priceDecimals: 4,
      pythId:
        '0x8963217838ab4cf5cadc172203c1f0b763fbaa45f346d8ee50ba994bbcac3026',
      address: '0x14872Af9b26401d806155cC6ccbf0e94E1661f1B',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ton.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'MKR',
      symbol: 'MKR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x9375299e31c0deb9c6bc378e6329aab44cb48ec655552a70d4b9050346a30378',
      address: '0x30c21B5Ff66BEa11e274C906d52ece45de6A32CF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/mkr.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'OP',
      symbol: 'OP',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf',
      address: '0x32504e654AfA2D9bbe4FA764494D59DB41FcA73a',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/op.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L2',
    },
    {
      name: 'ADA',
      symbol: 'ADA',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d',
      address: '0x345aa06a27A6aa0141650a740962135a6A6af4A0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ada.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'ATOM',
      symbol: 'ATOM',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xb00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819',
      address: '0x34d9EaF6739736Dc10474FCe2C1B4c0E43732E0d',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/atom.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'PENDLE',
      symbol: 'PENDLE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016',
      address: '0x367c72025644cD2D210f91794a1A03167957FeaF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/pendle.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'NEAR',
      symbol: 'NEAR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xc415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750',
      address: '0x38bAFFA92FDb8903c8E01668B65DE01E41069c2c',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/near.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'SHIB',
      symbol: 'SHIB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a',
      address: '0x391d855188177AA2854130641Fb5dA6263016bd0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/shib.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'MEME',
    },
    {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7',
      address: '0x408e768f7A425c11eA3dcec2ce5986B1583e7f8E',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/avax.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L2',
    },
    {
      name: 'RUNE',
      symbol: 'RUNE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x5fcf71143bb70d41af4fa9aa1287e2efd3c5911cee59f909f915c9f61baacb1e',
      address: '0x4128a0050D71a4c0838b883f422c1d62299287BF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/rune.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'BLUR',
      symbol: 'BLUR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x856aac602516addee497edf6f50d39e8c95ae5fb0da1ed434a8c2ab9c3e877e9',
      address: '0x5033b488652Cbca67D28C104F6577966e808B636',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/blur.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'DOT',
      symbol: 'DOT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b',
      address: '0x59a2Cc9dcea35309F00F15830433Acb40383c1c3',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/dot.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
      address: '0x5b9bA1964a20DA01571A000791C39B3A88AbE247',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/bnb.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'SUI',
      symbol: 'SUI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744',
      address: '0x64C3Dd6c99985a36E57BfA1Eb8cfedf85143d4Fa',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/sui.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'GLM',
      symbol: 'GLM',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x309d39a65343d45824f63dc6caa75dbf864834f48cfaa6deb122c62239e06474',
      address: '0x8b30db6D6D145aA2168e460a3f7984cE2Af9f51E',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/glm.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'FXS',
      symbol: 'FXS',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
      address: '0x966336009Be2dc127Aa0f7f586D691F02CE28260',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fxs.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'FET',
      symbol: 'FET',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xb98e7ae8af2d298d2651eb21ab5b8b5738212e13efb43bd0dfbce7a74ba4b5d0',
      address: '0x985641eBF214B35059d5aD1f77e9008D41786dA9',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fet.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'AAVE',
      symbol: 'AAVE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2b9ab1e972a281585084148ba1389800799bd4be63b957507db1349314e47445',
      address: '0x9C20109EF44E79f87f373fF09A05C590F4b04c3d',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/aave.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'XTZ',
      symbol: 'XTZ',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x0affd4b8ad136a21d79bc82450a325ee12ff55a235abc242666e423b8bcffd03',
      address: '0x9fbc78e3b6082073272524639c801b2c267c8ec0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/xtz.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Uniswap',
      symbol: 'UNI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501',
      address: '0xa64FdE29FE73Cb7623e6e249F72764A7691477F1',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/uni.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/uniswap',
      tag: 'DEFI',
    },
    {
      name: 'IMX',
      symbol: 'IMX',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x941320a8989414874de5aa2fc340a75d5ed91fdff1613dd55f83844d52ea63a2',
      address: '0xB8e40bb65Ba542b970251322c5245C935A32d53A',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/imx.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
      address: '0xBab631A77F97Cf7786c02f5981F5a048BC3254CD',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/arb.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/arbitrum',
      explorerUrl:
        'https://arbiscan.io/token/0x912ce59144191c1204e64559fe8253a0e49e6548',
      tag: 'L2',
    },
    {
      name: 'LDO',
      symbol: 'LDO',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xc63e2a7f37a04e5e614c07238bedb25dcc38927fba8fe890597a593c0b2fa4ad',
      address: '0xc168296Cf50b8207EF7200610e90c5F18bfE1E8A',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ldo.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'WIF',
      symbol: 'WIF',
      decimals: 6,
      priceDecimals: 4,
      pythId:
        '0x4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc',
      address: '0xC4A8d53eC0Fc9761C9E8DA0370e35CFfd4e0AB70',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/wif.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      needsLiveDataFetching: true,
    },
    {
      name: 'TIA',
      symbol: 'TIA',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723',
      address: '0xcb2a2BDD3e1393d36D894DC55a19853DD7B5c773',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/tia.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'PYTH',
      symbol: 'PYTH',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x0bbf28e9a841a1cc788f6a361b17ca072d0ea3098a1e5df1c3922d06719579ff',
      address: '0xd4b5BFD1614D2E08E4e1ec60ee89023E19B69164',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/pyth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'SEI',
      symbol: 'SEI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb',
      address: '0xD506AC7B9F46c87665cE47604bDf26BBE382f21b',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/sei.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'APT',
      symbol: 'APT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x03ae4db29ed4ae33d323568895aa00337e658e348b37509f5372ae51f0af00d5',
      address: '0xdAaD681bE36d1B76A7D221E3ff23e114511DA5EB',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/apt.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'ORDI',
      symbol: 'ORDI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0',
      address: '0xFbE14760cA6152dbCE3c0464D72e7F044196044a',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ordi.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'GRT',
      symbol: 'GRT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x4d1f8dae0d96236fb98e8f47471a366ec3b1732b47041781934ca3a9bb2f35e7',
      address: '0xfBe2906A3961D255bEf23FDE7f1C73ea15af5AAB',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/grt.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'FIL',
      symbol: 'FIL',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x150ac9b959aee0051e4091f0ef5216d941f590e1c5e7f91cf7635b5c11628c0e',
      address: '0xff66d3eFF5D80c1E983967F7295D7f397a7fEe63',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fil.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Dogecoin',
      symbol: 'DOGE',
      decimals: 8,
      priceDecimals: 4,
      isSynthetic: true,
      pythId:
        '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
      address: '0xeeEFAbDD0430e63450F55D03daF12090D98a83df',
      imageUrl: CLOUD_FRONT_URL + '/assets/doge.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      needsLiveDataFetching: true,
      tag: 'MEME',
    },
    {
      name: 'Litecoin',
      symbol: 'LTC',
      decimals: 18,
      pythId:
        '0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54',
      address: '0xF088c42803D094ca9c0D40DCC9CCD428FEF14DD7',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ltc.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/litecoin',
      tag: 'L1',
    },
    {
      name: 'XRP',
      symbol: 'XRP',
      decimals: 6,
      priceDecimals: 4,
      pythId:
        '0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8',
      address: '0xc14e065b0067dE91534e032868f5Ac6ecf2c6868',
      imageUrl: CLOUD_FRONT_URL + '/assets/xrp.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/xrp',
      isSynthetic: true,
      tag: 'L1',
    },
    {
      name: 'Chainlink',
      symbol: 'LINK',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
      address: '0x24637242DB75308a08B1061aE600cBb2a97c8DD5',
      isStable: false,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/link.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
    {
      name: 'GMX Market tokens',
      symbol: 'RM',
      address: '<market-token-address>',
      decimals: 18,
      imageUrl:
        'https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png',
      isPlatformToken: true,
    },
    {
      name: 'wstETH',
      symbol: 'wstETH',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0x6df640f3b8963d8f8358f791f352b8364513f6ab1cca5ed3f1f7b5448980e784',
      address: '0x1c6580aF3A629e4b925F07996084FA57E85e3dD4',
      isStable: false,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/wstETH.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
    {
      name: 'ZK',
      symbol: 'ZK',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0xcc03dc09298fb447e0bf9afdb760d5b24340fd2167fd33d8967dd8f9a141a2e8',
      address: '0xCe41E57157ab44710442f499b30075A6E2bC2B97',
      isStable: false,
      isShortable: true,
      imageUrl: '/icons/zksync.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
  ],
  [ZKSYNC]: [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      id: 'ethereum',
      address: ethers.constants.AddressZero,
      pythId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      isNative: true,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
      isV1Available: true,
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
      tag: 'L1',
    },
    {
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      id: 'ethereum',
      decimals: 18,
      address: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      pythId:
        '0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6',
      isWrapped: true,
      baseSymbol: 'ETH',
      imageUrl: CLOUD_FRONT_URL + '/assets/weth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
    },
    {
      name: 'ETH/SOL',
      symbol: 'ETH/SOL',
      address: '0xB615d0ae9011f339614947deE09B2D7d2e134567',
      pythId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      baseId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      quoteAddress: '0x138DCa29f0dF3492bce88f1E897a23b04c161913',

      baseAddress: '0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      //TODO: remove explorerUrl in future
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'SOL/ETH',
      symbol: 'SOL/ETH',
      address: '0xea3E1b07Afc7021F1F3eC5F2e2B02C78E68418bc',
      pythId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      baseId:
        '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      quoteAddress: '0x0Cedf7c3e40847393CeFD0C51aDb515916F2e745',
      baseAddress: '0x138DCa29f0dF3492bce88f1E897a23b04c161913',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      //TODO: remove explorerUrl in future
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'Wrapped SOL (Wormhole)',
      symbol: 'SOL',
      id: 'solana',
      pythId:
        '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
      decimals: 9,
      address: '0x2Bd49911c2816f74116404F8EAC8876e120fb4b9',
      imageUrl: CLOUD_FRONT_URL + '/assets/sol.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/solana',
      explorerUrl:
        'https://arbiscan.io/token/0x2bCc6D6CdBbDC0a4071e48bb3B969b06B3330c07',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
      tag: 'L1',
    },
    // {
    //   name: 'Bridged USDC (USDC.e)',
    //   symbol: 'USDC.e',
    //   decimals: 6,
    //   address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    //   isStable: true,
    //   imageUrl: CLOUD_FRONT_URL + '/assets/usdc.svg',
    //   coingeckoUrl: 'https://www.coingecko.com/en/coins/usd-coin',
    //   explorerUrl:
    //     'https://arbiscan.io/token/0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    //   isV1Available: true,
    // },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      address: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
      pythId:
        '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
      isStable: true,
      isV1Available: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/usdc.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/usd-coin',
      explorerUrl:
        'https://arbiscan.io/address/0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      needsLiveDataFetching: true,
    },
    {
      name: 'Tether',
      symbol: 'USDT',
      decimals: 6,
      address: '0x81E8CC7F05dBd6BcE8014A4bc3b2C7DA11148Fcf',
      pythId:
        '0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b',
      isStable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/usdt.svg',
      explorerUrl:
        'https://arbiscan.io/address/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/tether',
      isV1Available: true,
    },
    {
      name: 'GMX Market tokens',
      symbol: 'RM',
      address: '<market-token-address>',
      decimals: 18,
      imageUrl:
        'https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png',
      isPlatformToken: true,
    },
    {
      name: 'Dai',
      symbol: 'DAI',
      decimals: 18,
      address: '0x2b3558FDf3A2a1c4733eb1F9e226C37E367F6E72',
      pythId:
        '0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd',
      isStable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/dai.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dai',
      explorerUrl:
        'https://arbiscan.io/token/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      isV1Available: true,
    },
    {
      name: 'Bitcoin',
      symbol: 'BTC',
      id: 'bitcoin',
      address: '0x00957c690A5e3f329aDb606baD99cEd9Ad701a98',
      pythId:
        '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
      decimals: 8,
      imageUrl: CLOUD_FRONT_URL + '/assets/btc.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      tag: 'L1',
    },
    {
      name: 'PEPE',
      symbol: 'PEPE',
      id: 'pepe',
      address: '0x1d39C107308B3C963Cf0305B17710450AFFe0C5E',
      pythId:
        '0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4',
      priceDecimals: 16,
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/pepe.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      needsLiveDataFetching: true,
      tag: 'MEME',
    },
    {
      name: 'ETH/BTC',
      symbol: 'ETH/BTC',
      address: '0x2dEd5585e0bE28bb0735D01c403F064a8986d2B9',
      pythId:
        '0xc96458d393fe9deb7a7d63a0ac41e2898a67a7750dbd166673279e06c868df0a',
      isSynthetic: true,
      decimals: 18,
      imageUrl: CLOUD_FRONT_URL + '/assets/eth.svg',
      explorerUrl:
        'https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      isChartForMultiChartPage: true,
      needsLiveDataFetching: true,
    },
    {
      name: 'INJ',
      symbol: 'INJ',
      decimals: 18,
      priceDecimals: 4,
      address: '0x00FF6962e7A5799e1497CEcc915453929C4E3EE5',
      pythId:
        '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/inj.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'TON',
      symbol: 'TON',
      decimals: 9,
      priceDecimals: 4,
      pythId:
        '0x8963217838ab4cf5cadc172203c1f0b763fbaa45f346d8ee50ba994bbcac3026',
      address: '0x14872Af9b26401d806155cC6ccbf0e94E1661f1B',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ton.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'MKR',
      symbol: 'MKR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x9375299e31c0deb9c6bc378e6329aab44cb48ec655552a70d4b9050346a30378',
      address: '0x30c21B5Ff66BEa11e274C906d52ece45de6A32CF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/mkr.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'OP',
      symbol: 'OP',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf',
      address: '0x32504e654AfA2D9bbe4FA764494D59DB41FcA73a',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/op.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L2',
    },
    {
      name: 'ADA',
      symbol: 'ADA',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d',
      address: '0x345aa06a27A6aa0141650a740962135a6A6af4A0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ada.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'ATOM',
      symbol: 'ATOM',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xb00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819',
      address: '0x34d9EaF6739736Dc10474FCe2C1B4c0E43732E0d',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/atom.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'PENDLE',
      symbol: 'PENDLE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016',
      address: '0x367c72025644cD2D210f91794a1A03167957FeaF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/pendle.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'NEAR',
      symbol: 'NEAR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xc415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750',
      address: '0x38bAFFA92FDb8903c8E01668B65DE01E41069c2c',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/near.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'SHIB',
      symbol: 'SHIB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a',
      address: '0x391d855188177AA2854130641Fb5dA6263016bd0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/shib.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'MEME',
    },
    {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7',
      address: '0x408e768f7A425c11eA3dcec2ce5986B1583e7f8E',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/avax.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L2',
    },
    {
      name: 'RUNE',
      symbol: 'RUNE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x5fcf71143bb70d41af4fa9aa1287e2efd3c5911cee59f909f915c9f61baacb1e',
      address: '0x4128a0050D71a4c0838b883f422c1d62299287BF',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/rune.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'BLUR',
      symbol: 'BLUR',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x856aac602516addee497edf6f50d39e8c95ae5fb0da1ed434a8c2ab9c3e877e9',
      address: '0x5033b488652Cbca67D28C104F6577966e808B636',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/blur.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'DOT',
      symbol: 'DOT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b',
      address: '0x59a2Cc9dcea35309F00F15830433Acb40383c1c3',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/dot.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
      address: '0x5b9bA1964a20DA01571A000791C39B3A88AbE247',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/bnb.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'SUI',
      symbol: 'SUI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744',
      address: '0x64C3Dd6c99985a36E57BfA1Eb8cfedf85143d4Fa',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/sui.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'GLM',
      symbol: 'GLM',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x309d39a65343d45824f63dc6caa75dbf864834f48cfaa6deb122c62239e06474',
      address: '0x8b30db6D6D145aA2168e460a3f7984cE2Af9f51E',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/glm.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'FXS',
      symbol: 'FXS',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
      address: '0x966336009Be2dc127Aa0f7f586D691F02CE28260',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fxs.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'FET',
      symbol: 'FET',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xb98e7ae8af2d298d2651eb21ab5b8b5738212e13efb43bd0dfbce7a74ba4b5d0',
      address: '0x985641eBF214B35059d5aD1f77e9008D41786dA9',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fet.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'AAVE',
      symbol: 'AAVE',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x2b9ab1e972a281585084148ba1389800799bd4be63b957507db1349314e47445',
      address: '0x9C20109EF44E79f87f373fF09A05C590F4b04c3d',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/aave.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'XTZ',
      symbol: 'XTZ',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x0affd4b8ad136a21d79bc82450a325ee12ff55a235abc242666e423b8bcffd03',
      address: '0x9fbc78e3b6082073272524639c801b2c267c8ec0',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/xtz.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Uniswap',
      symbol: 'UNI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501',
      address: '0xa64FdE29FE73Cb7623e6e249F72764A7691477F1',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/uni.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/uniswap',
      tag: 'DEFI',
    },
    {
      name: 'IMX',
      symbol: 'IMX',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x941320a8989414874de5aa2fc340a75d5ed91fdff1613dd55f83844d52ea63a2',
      address: '0xB8e40bb65Ba542b970251322c5245C935A32d53A',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/imx.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
      address: '0xBab631A77F97Cf7786c02f5981F5a048BC3254CD',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/arb.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/arbitrum',
      explorerUrl:
        'https://arbiscan.io/token/0x912ce59144191c1204e64559fe8253a0e49e6548',
      tag: 'L2',
    },
    {
      name: 'LDO',
      symbol: 'LDO',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0xc63e2a7f37a04e5e614c07238bedb25dcc38927fba8fe890597a593c0b2fa4ad',
      address: '0xc168296Cf50b8207EF7200610e90c5F18bfE1E8A',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ldo.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'DEFI',
    },
    {
      name: 'WIF',
      symbol: 'WIF',
      decimals: 6,
      priceDecimals: 4,
      pythId:
        '0x4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc',
      address: '0xC4A8d53eC0Fc9761C9E8DA0370e35CFfd4e0AB70',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/wif.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      needsLiveDataFetching: true,
    },
    {
      name: 'TIA',
      symbol: 'TIA',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723',
      address: '0xcb2a2BDD3e1393d36D894DC55a19853DD7B5c773',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/tia.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'PYTH',
      symbol: 'PYTH',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x0bbf28e9a841a1cc788f6a361b17ca072d0ea3098a1e5df1c3922d06719579ff',
      address: '0xd4b5BFD1614D2E08E4e1ec60ee89023E19B69164',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/pyth.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'SEI',
      symbol: 'SEI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb',
      address: '0xD506AC7B9F46c87665cE47604bDf26BBE382f21b',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/sei.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'APT',
      symbol: 'APT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x03ae4db29ed4ae33d323568895aa00337e658e348b37509f5372ae51f0af00d5',
      address: '0xdAaD681bE36d1B76A7D221E3ff23e114511DA5EB',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/apt.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'ORDI',
      symbol: 'ORDI',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0',
      address: '0xFbE14760cA6152dbCE3c0464D72e7F044196044a',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ordi.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
    },
    {
      name: 'GRT',
      symbol: 'GRT',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x4d1f8dae0d96236fb98e8f47471a366ec3b1732b47041781934ca3a9bb2f35e7',
      address: '0xfBe2906A3961D255bEf23FDE7f1C73ea15af5AAB',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/grt.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'FIL',
      symbol: 'FIL',
      decimals: 18,
      priceDecimals: 4,
      pythId:
        '0x150ac9b959aee0051e4091f0ef5216d941f590e1c5e7f91cf7635b5c11628c0e',
      address: '0xff66d3eFF5D80c1E983967F7295D7f397a7fEe63',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/fil.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      tag: 'L1',
    },
    {
      name: 'Dogecoin',
      symbol: 'DOGE',
      decimals: 8,
      priceDecimals: 4,
      isSynthetic: true,
      pythId:
        '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
      address: '0xeeEFAbDD0430e63450F55D03daF12090D98a83df',
      imageUrl: CLOUD_FRONT_URL + '/assets/doge.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dogecoin',
      needsLiveDataFetching: true,
      tag: 'MEME',
    },
    {
      name: 'Litecoin',
      symbol: 'LTC',
      decimals: 18,
      pythId:
        '0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54',
      address: '0xF088c42803D094ca9c0D40DCC9CCD428FEF14DD7',
      isSynthetic: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/ltc.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/litecoin',
      tag: 'L1',
    },
    {
      name: 'XRP',
      symbol: 'XRP',
      decimals: 6,
      priceDecimals: 4,
      pythId:
        '0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8',
      address: '0xc14e065b0067dE91534e032868f5Ac6ecf2c6868',
      imageUrl: CLOUD_FRONT_URL + '/assets/xrp.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/xrp',
      isSynthetic: true,
      tag: 'L1',
    },
    {
      name: 'Chainlink',
      symbol: 'LINK',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
      address: '0x24637242DB75308a08B1061aE600cBb2a97c8DD5',
      isStable: false,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/link.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
    {
      name: 'GMX Market tokens',
      symbol: 'RM',
      address: '<market-token-address>',
      decimals: 18,
      imageUrl:
        'https://raw.githubusercontent.com/gmx-io/gmx-assets/main/GMX-Assets/PNG/GM_LOGO.png',
      isPlatformToken: true,
    },
    {
      name: 'wstETH',
      symbol: 'wstETH',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0x6df640f3b8963d8f8358f791f352b8364513f6ab1cca5ed3f1f7b5448980e784',
      address: '0x703b52F2b28fEbcB60E1372858AF5b18849FE867',
      isStable: false,
      isShortable: true,
      imageUrl: CLOUD_FRONT_URL + '/assets/wstETH.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
    {
      name: 'ZK',
      symbol: 'ZK',
      decimals: 18,
      priceDecimals: 3,
      pythId:
        '0xcc03dc09298fb447e0bf9afdb760d5b24340fd2167fd33d8967dd8f9a141a2e8',
      address: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
      isStable: false,
      isShortable: true,
      imageUrl: '/icons/zksync.svg',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/chainlink',
      explorerUrl:
        'https://arbiscan.io/token/0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
      tag: 'DEFI',
    },
  ],
}

export const GLP_POOL_COLORS = {
  ETH: '#6062a6',
  BTC: '#F7931A',
  WBTC: '#F7931A',
  USDC: '#2775CA',
  'USDC.e': '#2A5ADA',
  USDT: '#67B18A',
  MIM: '#9695F8',
  FRAX: '#000',
  DAI: '#FAC044',
  UNI: '#E9167C',
  AVAX: '#E84142',
  LINK: '#3256D6',
}

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } =
  {}
export const V1_TOKENS: { [chainId: number]: Token[] } = {}
export const V2_TOKENS: { [chainId: number]: Token[] } = {}
export const TOKENS_BY_SYMBOL_MAP: {
  [chainId: number]: { [symbol: string]: Token }
} = {}
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {}
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {}

const CHAIN_IDS = [ZKSYNC_SEPOLIA, ZKSYNC]

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j]

  TOKENS_MAP[chainId] = {}
  TOKENS_BY_SYMBOL_MAP[chainId] = {}
  V1_TOKENS[chainId] = []
  V2_TOKENS[chainId] = []

  let tokens = TOKENS[chainId]
  let wrappedTokenAddress: string | undefined

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    TOKENS_MAP[chainId][token.address] = token
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token

    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token
      wrappedTokenAddress = token.address
    }

    if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token
    }

    if (token.isV1Available && !token.isTempHidden) {
      V1_TOKENS[chainId].push(token)
    }

    if (!token.isPlatformToken && !token.isTempHidden) {
      V2_TOKENS[chainId].push(token)
    }
  }

  NATIVE_TOKENS_MAP[chainId].wrappedAddress = wrappedTokenAddress
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId]
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId]
}

export function getTokens(chainId: number) {
  return TOKENS[chainId]
}

export function getV1Tokens(chainId: number) {
  return V1_TOKENS[chainId]
}

export function getV2Tokens(chainId: number) {
  return V2_TOKENS[chainId]
}

export function getTokensMap(chainId: number) {
  return TOKENS_MAP[chainId]
}

export function getWhitelistedV1Tokens(chainId: number) {
  return getV1Tokens(chainId)
}

export function getVisibleV1Tokens(chainId: number) {
  return getV1Tokens(chainId).filter((token) => !token.isWrapped)
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.log(`Incorrect chainId ${chainId}`)
    chainId = DEFAULT_CHAIN_ID
    // throw new Error(`Incorrect chainId ${chainId}`)
  }
  return address in TOKENS_MAP[chainId]
}

function getToken(chainId: number, address: string, shouldThrow?: true): Token
function getToken(
  chainId: number,
  address: string,
  shouldThrow?: false,
): Token | undefined
function getToken(
  chainId: number,
  address: string,
  shouldThrow: boolean = true,
): Token | undefined {
  if (!TOKENS_MAP[chainId]) {
    const msg = `Incorrect chainId ${chainId}`
    if (shouldThrow) {
      throw new Error(msg)
    } else {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log(msg)
    }
  }
  if (!TOKENS_MAP[chainId][address]) {
    const msg = `Incorrect address "${address}" for chainId ${chainId}`
    if (shouldThrow) {
      throw new Error(msg)
    } else {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log(msg)
    }
  }

  return TOKENS_MAP[chainId][address]
}
export { getToken }

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol]
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`)
  }
  return token
}

export function convertTokenAddress(
  chainId: number,
  address: string,
  convertTo?: 'wrapped' | 'native',
) {
  const wrappedToken = getWrappedToken(chainId)

  if (convertTo === 'wrapped' && address === NATIVE_TOKEN_ADDRESS) {
    return wrappedToken.address
  }

  if (convertTo === 'native' && address === wrappedToken.address) {
    return NATIVE_TOKEN_ADDRESS
  }

  return address
}

export function getNormalizedTokenSymbol(tokenSymbol: string): string {
  if (['WBTC', 'WETH', 'WAVAX'].includes(tokenSymbol)) {
    return tokenSymbol.substr(1)
  } else if (tokenSymbol === 'BTC.b') {
    return 'BTC'
  }
  return tokenSymbol
}

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  let token

  try {
    token = getTokenBySymbol(chainId, tokenSymbol)
  } catch (e) {
    return false
  }

  if (token.isChartDisabled || token.isPlatformToken) {
    return false
  }

  return true
}

export function getPriceDecimals(chainId: number, tokenSymbol?: string) {
  if (!tokenSymbol) {
    return 2
  }

  try {
    const token = getTokenBySymbol(chainId, tokenSymbol)
    return token.priceDecimals ?? 2
  } catch (e) {
    return 2
  }
}
