import { getDefaultConfig, WalletList } from '@rainbow-me/rainbowkit'
import {
  coinbaseWallet,
  coreWallet,
  metaMaskWallet,
  okxWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { createPublicClient, http } from 'viem'
import { zksync, zksyncSepoliaTestnet } from 'viem/chains'

const WALLET_CONNECT_PROJECT_ID = '6572e4a7d8957027454168f031ce3c30'
const APP_NAME = 'RFX'

export const publicClient = createPublicClient({
  chain:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? zksync
      : zksyncSepoliaTestnet,
  transport: http(
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'https://zksync-mainnet.g.alchemy.com/v2/j0lX58c1BJA579sJxqo4V5v6OYoWhu0Q'
      : '',
  ),
})

const popularWalletList: WalletList = [
  {
    // Group name with standard name is localized by rainbow kit
    groupName: 'Popular',
    wallets: [metaMaskWallet, walletConnectWallet],
  },
]

const othersWalletList: WalletList = [
  {
    groupName: 'Others',
    wallets: [coinbaseWallet, trustWallet, coreWallet, okxWallet],
  },
]

export const rainbowKitConfig = getDefaultConfig({
  appName: APP_NAME,
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? [zksync]
      : [zksyncSepoliaTestnet],
  transports:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? {
          [zksync.id]: http(),
        }
      : {
          [zksyncSepoliaTestnet.id]: http(),
        },
  wallets: [...popularWalletList, ...othersWalletList],
})
